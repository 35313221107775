import { MembershipStatus } from '@wix/ambassador-social-groups-v2-group-member/types';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { IGroupV2 } from '../../contexts/Group/IGorupV2';
import { restrictedByAdmin } from '@wix/social-groups-api/dist/src/model/v2/Group/accessRestriction';
import {
  joined,
  canJoinGroup,
} from '@wix/social-groups-api/dist/src/model/v2/Membership';
import { Button as ButtonType } from '../../types/button';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useContext } from 'react';
import { AppDataContext } from '../../contexts/AppData/AppData';

export interface MembershipButtonConfig {
  buttonLabelKey: string;
  priority: PRIORITY;

  onClick?(): void;
}

type Config = {
  [key in MembershipStatus]: MembershipButtonConfig;
};

function getJoinLabel(groupV2: IGroupV2['groupV2']) {
  const accessRestriction = groupV2!.accessRestriction!;
  if (!accessRestriction) {
    return 'groups-web.join';
  }
  if (restrictedByAdmin(accessRestriction)) {
    return 'groups-web.request-to-join';
  }
  return 'groups-web.join';
}

export function shouldRenderMembershipButton(
  { groupV2, membership }: IGroupV2,
  { isMobile, isPreview, isEditor, activeButton }: any,
) {
  if (!groupV2 || joined(membership!)) {
    return false;
  }
  if (isEditor && !isPreview && !isMobile) {
    return activeButton === ButtonType.PRIMARY;
  }

  if (!canJoinGroup(groupV2, membership!)) {
    return false;
  }

  return true;
}

export function useMembershipButtonPreferences(
  group: IGroupV2,
): MembershipButtonConfig | null {
  const { isMobile, isPreview, isEditor } = useEnvironment();
  const { activeButton } = useContext(AppDataContext);
  const { groupV2, membership } = group;
  if (
    !shouldRenderMembershipButton(group, {
      isMobile,
      isPreview,
      isEditor,
      activeButton,
    })
  ) {
    return null;
  }
  const { status } = membership!;
  const config: Config = {
    [MembershipStatus.UNKNOWN_STATUS]: {
      buttonLabelKey: getJoinLabel(groupV2!),
      priority: PRIORITY.primary,
    },
    [MembershipStatus.PENDING]: {
      buttonLabelKey: 'groups-web.pending',
      priority: PRIORITY.secondary,
    },
    [MembershipStatus.JOINED]: {
      buttonLabelKey: 'groups-web.joined',
      priority: PRIORITY.primary,
    },
  };
  return config[status!];
}
