import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes } from './MembersWidget.st.css';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { LoginParams } from '../../../../../common/components/LoginView/LoginView';

export const LoginText: React.FC<LoginParams> = () => {
  const { t } = useTranslation();
  return (
    <Text className={classes.description}>
      {t('groups-web.members-widget.log-in-to-view')}
    </Text>
  );
};

LoginText.displayName = 'LoginText';

export const LoginButton: React.FC<LoginParams> = ({ promptLogin }) => {
  const { t } = useTranslation();

  return (
    <TextButton
      priority={TEXT_BUTTON_PRIORITY.primary}
      onClick={() => promptLogin({ modal: true })}
    >
      {t('groups-web.members-widget.log-in')}
    </TextButton>
  );
};
