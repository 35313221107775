import { classes } from './PendingMembers.st.css';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import React from 'react';
import { TFunction } from '@wix/yoshi-flow-editor';

export const Title: React.FC<{ total: number; t: TFunction }> = ({
  total,
  t,
}) => {
  {
    const title = `${t('groups-web.members.pending.requests')}`;
    const number = total < 10 ? total : '9+';
    return (
      <div className={classes.titleWrapper}>
        <Text typography={TYPOGRAPHY.runningText} className={classes.title}>
          {title}
        </Text>
        <span className={classes.badge}>{number}</span>
      </div>
    );
  }
};
