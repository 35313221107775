import React from 'react';

import { canInviteMembers } from '@wix/social-groups-api';

import Add from 'wix-ui-icons-common/Add';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../contexts/TPAComponent/withTpaComponentsConfig';
import {
  InjectedBiLoggerProps,
  InjectedEnvironmentProps,
  withBi,
  withEnvironment,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import {
  withAppData,
  WithAppDataProps,
} from '../../contexts/AppData/withAppData';
import { Button as ButtonType } from '../../types/button';
import { Button } from '../../../../common/components/Button';
import { AddMembersModal } from '../Modals/AddMemberModal/AddMembersModal';
import { compose } from '../../../../common/utils/compose';
import { groupsAddMemberClicked } from '@wix/bi-logger-groups/v2';

export const INVITE_MEMBERS = 'invite-members';

interface InviteMembersProps {}

interface InviteMembersState {
  isModalOpen: boolean;
}

type Props = InviteMembersProps &
  WithGroupProps &
  WithTpaComponentsConfigProps &
  WithTranslation &
  WithAppDataProps &
  InjectedBiLoggerProps &
  InjectedEnvironmentProps;

export class InviteMembersComponent extends React.Component<
  Props,
  InviteMembersState
> {
  readonly state: InviteMembersState = {
    isModalOpen: false,
  };

  shouldRender() {
    const {
      group,
      environment: { isEditor, isMobile, isPreview },
      activeButton,
    } = this.props;

    if (isEditor) {
      if (isMobile) {
        return true;
      }
      if (isPreview) {
        return true;
      }

      return activeButton === ButtonType.SECONDARY;
    }

    const canAddMembers = canInviteMembers(group);
    if (canAddMembers) {
      return true;
    }
  }

  render() {
    const { group, mobile, t } = this.props;

    if (!this.shouldRender()) {
      return null;
    }

    const { isModalOpen } = this.state;
    return (
      <>
        <Button
          fullWidth
          priority={PRIORITY.secondary}
          prefixIcon={mobile ? undefined : <Add width="24px" height="24px" />}
          onClick={this.openModal}
          area-label={t('groups-web.a11y.invite-members')}
          data-hook={INVITE_MEMBERS}
        >
          {t('groups-web.group.actions.invite-members.title')}
        </Button>
        <AddMembersModal
          group={group}
          isOpen={isModalOpen}
          handleClose={this.onCloseModal}
        />
      </>
    );
  }

  private readonly onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  private readonly openModal = () => {
    const { bi, group } = this.props;
    bi.report(
      groupsAddMemberClicked({
        groupId: group.groupId!,
        origin: 'group_member_icon_btn',
      }),
    );
    this.setState({ isModalOpen: true });
  };
}

const enhance = compose(
  WithGroup,
  withTpaComponentsConfig,
  withBi,
  withTranslation(),
  withAppData,
  withEnvironment,
);
export const InviteMembers = enhance(
  InviteMembersComponent,
) as React.ComponentType<InviteMembersProps>;
InviteMembers.displayName = 'InviteMembers';
