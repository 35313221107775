import React from 'react';
import { IGroupActions } from './IGroupActions';

export const aDefaultGroupActions: GroupActionsContextType = {
  deleteGroup() {},
  setEditMode() {},
  updateGroup() {},
  uploadFiles() {},
  getExternalVideoMetadata() {},
  changeTab() {},
  goToGroupList() {},
  inviteMembersByEmail(emails: string[]) {},
  configureApps() {},
  fetchGroupRules() {},
  // saveMembershipQuestions() {
  //   return null as any;
  // },
  // getMembershipQuestions() {
  //   return null as any;
  // },
};

export type GroupActionsContextType = IGroupActions;

export const GroupActionsContext = React.createContext<GroupActionsContextType>(
  aDefaultGroupActions,
);

GroupActionsContext.displayName = 'GroupActionsContext';

export const GroupActionsProvider = GroupActionsContext.Provider;
export const GroupActionsConsumer = GroupActionsContext.Consumer;
