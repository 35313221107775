import * as React from 'react';
import { Member } from './Member/Member';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { Theme } from '../../../../common/context/theme/Theme';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useGroupMember } from '../hooks/useGroupMember';
import { GroupMember } from '@wix/social-groups-api/dist/src/model/Member/GroupMember';

export interface MembersListProps extends Theme {
  members: ApiTypes.v1.GroupMemberResponse[];
  withMoreActions?: boolean; // TODO: refactor for composition https://reactjs.org/docs/composition-vs-inheritance.html
  className?: string;
  onChangeAdmin?(memberId: string): void;
  onRemove?(memberId: string): void;
  onViewAnswers?(memberId: string): void;
}

export const MembersList: React.FC<MembersListProps> = (props) => {
  const {
    className,
    withMoreActions,
    members,
    onChangeAdmin,
    onRemove,
    onViewAnswers,
    forceBlackAndWhite,
  } = props;
  const { isMobile } = useEnvironment();
  const { groupMember, isMe } = useGroupMember();
  const isAdmin = groupMember.isAdmin();
  const renderMember = (_member: any) => {
    const member = new GroupMember(_member);
    const isCurrentUser = isMe(member.getId());
    const showMemberActions = withMoreActions && !isCurrentUser && isAdmin;
    return (
      <Member
        forceBlackAndWhite={forceBlackAndWhite}
        isCurrentUser={isCurrentUser}
        member={member}
        mobile={isMobile}
        withMoreActions={withMoreActions!}
        onChangeAdmin={onChangeAdmin!}
        onRemove={onRemove!}
        onViewAnswers={onViewAnswers!}
        showMemberActions={!!showMemberActions}
      />
    );
  };

  return <ul className={className}>{members.map(renderMember)}</ul>;
};
