import React from 'react';

import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import {
  Dialog,
  DialogProps,
} from '../../../../../common/components/Dialog/Dialog';
import { st, classes } from './ChangeAdminRoleDialog.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { compose } from '../../../../../common/utils/compose';
import { Theme, withTheme } from '../../../../../common/context/theme';

export interface ChangeAdminRoleDialogProps extends DialogProps {
  name: string;
  image: string;
  isAdmin: boolean;
  onChangeAdminRole(): void;
}

const adminPermissionKeys = [
  'groups-web.group.actions.admin-role-description.posts',
  'groups-web.group.actions.admin-role-description.comments',
  'groups-web.group.actions.admin-role-description.members',
];
const translations = {
  addAdmin: {
    okLabel: 'groups-web.group.actions.add-admin-role.modal.confirm',
    title: 'groups-web.group.actions.add-admin-role.modal.title',
    description: 'groups-web.group.actions.add-admin-role.modal.description',
  },
  removeAdmin: {
    okLabel: 'groups-web.group.actions.remove-admin-role.modal.confirm',
    title: 'groups-web.group.actions.remove-admin-role.modal.title',
    description: 'groups-web.group.actions.remove-admin-role.modal.description',
  },
};
// TODO: TD refactor
const ChangeAdminRoleDialogComponent: React.FC<
  ChangeAdminRoleDialogProps & WithTranslation & Theme
> = (props) => {
  const {
    t,
    onChangeAdminRole,
    isOpen,
    name,
    image,
    onRequestClose,
    isAdmin,
    forceBlackAndWhite,
  } = props;
  const { okLabel, description, title } = isAdmin
    ? translations.removeAdmin
    : translations.addAdmin;

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      cancelLabel={t('groups-web.cancel')}
      okLabel={t(okLabel)}
      onOkClick={onChangeAdminRole}
    >
      <div className={st(classes.root, { bw: !!forceBlackAndWhite })}>
        <div className={classes.header}>
          <Avatar size={AvatarSize.large} src={image} name={name} />
          <Text
            typography={TYPOGRAPHY.runningText}
            className={classes.memberName}
          >
            {name}
          </Text>
          <Text
            typography={TYPOGRAPHY.largeTitle}
            tagName="h1"
            className={classes.title}
          >
            {t(title)}
          </Text>
        </div>
        <div>
          <Text
            typography={TYPOGRAPHY.runningText}
            className={classes.description}
            tagName="div"
          >
            {t(description, { memberFullName: name })}
          </Text>

          <ul className={classes.list}>
            {adminPermissionKeys.map((translationKey) => (
              <Text
                key={translationKey}
                typography={TYPOGRAPHY.runningText}
                tagName="li"
                className={classes.adminPermissionOption}
              >
                {t(translationKey)}
              </Text>
            ))}
          </ul>
        </div>
      </div>
    </Dialog>
  );
};

const enhance = compose(withTranslation(), withTheme);

export const ChangeAdminRoleDialog = enhance(
  ChangeAdminRoleDialogComponent,
) as React.ComponentType<ChangeAdminRoleDialogProps>;
ChangeAdminRoleDialog.displayName = 'ChangeAdminRoleDialog';
