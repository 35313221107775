import React from 'react';
import { CoverImageLayout } from '../../Settings/settingsConstants';
import { classes } from './Header.st.css';
import { LoadingBehaviorOptions, ResizeOptions } from 'wix-ui-tpa/HeroImage';
import {
  SuperHeroImage,
  SuperHeroImageChanges,
} from '../../../../common/components/SuperHeroImage/SuperHeroImage';
import { useGroupSettings } from '../hooks/useGroupSettings';
import {
  getRepositioningLogo,
  RepositioningLogo,
} from './getRepositioningLogo';
import { useGroupWrapper } from '../../../../common/hooks/useGroupWrapper';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { useExperiments } from '@wix/yoshi-flow-editor';

interface Props {
  group: ApiTypes.v1.GroupResponse;
  onChange(changes: SuperHeroImageChanges): void;
  onSaved(): void;
}
const GROUP_IMAGE = 'group-image';

export const Cover: React.FC<Props> = ({ group, onChange, onSaved }) => {
  const { coverImageLayout, targetWidth, targetHeight } = useGroupSettings();
  const g = useGroupWrapper(group);
  const { experiments } = useExperiments();
  let repositioningLogo: RepositioningLogo = {};
  if (g.logo) {
    repositioningLogo = getRepositioningLogo(g, {
      targetWidth,
      targetHeight,
    });
  }
  const isLarge = coverImageLayout === CoverImageLayout.large;
  const { logoUrl, height, focalPointY } = repositioningLogo;
  return (
    <SuperHeroImage
      src={logoUrl}
      initialSourceHeight={height!}
      initialFocalPointY={focalPointY!}
      onSave={onChange}
      onSaved={onSaved}
      showEditControls={
        g.admin &&
        isLarge &&
        experiments.enabled('specs.groups.CoverImageReposition')
      }
      fluid={isLarge}
      className={classes.largeImage}
      width={targetWidth}
      height={targetHeight}
      loadingBehavior={LoadingBehaviorOptions.blur}
      resize={ResizeOptions.cover}
      data-hook={GROUP_IMAGE}
    />
  );
};

Cover.displayName = 'Cover';
