import React from 'react';
import { Check, LinkIcon } from '../Icons';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Block, BlockAlign, BlockFlow } from '../Block';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { classes } from './ShareModal.st.css';

interface ClipboardCopyProps {
  text: string;
  copiedLabel?: string;
  tooltip?: string;
}
interface ClipboardCopyState {
  copiedToClipboard: boolean;
}
export class ClipboardCopy extends React.Component<
  ClipboardCopyProps,
  ClipboardCopyState
> {
  state: ClipboardCopyState = { copiedToClipboard: false };

  async copyToClipBoard(): Promise<void> {
    await navigator.clipboard.writeText(this.props.text);
    this.setState({ copiedToClipboard: true });
    setTimeout(() => this.setState({ copiedToClipboard: false }), 1500);
  }
  render() {
    const { tooltip } = this.props;
    return (
      <Tooltip
        content={tooltip || 'Copy Link'}
        placement="bottom"
        minWidth={90}
      >
        <IconButton
          className={classes.shareIcon}
          skin={Skins.Full}
          icon={this.getIcon()}
          onClick={() => this.copyToClipBoard()}
          aria-label="Copy Link"
        />
      </Tooltip>
    );
  }

  private getIcon() {
    const { copiedLabel } = this.props;
    return this.state.copiedToClipboard ? (
      <Block flow={BlockFlow.row} justify={BlockAlign.center}>
        <Check className={classes.check} />
        <Text typography={TYPOGRAPHY.runningText} className={classes.smallText}>
          {copiedLabel || 'Link Copied'}
        </Text>
      </Block>
    ) : (
      <LinkIcon />
    );
  }
}
