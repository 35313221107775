import React from 'react';
import { NotificationSettings } from './INotificationSettings';

export const NotificationSettingsContext = React.createContext<NotificationSettings>(
  {
    notificationActions: undefined as any,
    notificationSettings: undefined as any,
  },
);
NotificationSettingsContext.displayName = 'NotificationSettingsContext';
