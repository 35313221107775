import React, { useEffect, useState } from 'react';
import { NewPost } from './NewPost';
import { NewPostModal } from '../../Modals/NewPostModal/NewPostModal';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import { ActionType } from '../../UserStatus/ActionType';
import { useUser } from '../../../../../common/context/user/useUser';
import { memberWrapper } from '../../../../../common/api/model/Member';
import { removeCreateNewPostDeepLink } from './index';
import { FeedControllerProps } from '../../../types/FeedControllerProps';
import { RequestState } from '@wix/social-groups-api/dist/src/services/RequestState';
import { useUserActions } from '../../UserStatus/useUserActions';
import { FeedRequestState } from 'Group/types/FeedRequestState';

interface Props extends Pick<FeedControllerProps, 'feedRequest'> {
  onCreatePost(content: any, topicId?: string): void;

  topicId?: string;
}

function isPostCreating(feedRequest?: FeedRequestState) {
  return feedRequest?.createPost === RequestState.PENDING;
}

export const WritePost: React.FC<Props> = ({
  onCreatePost,
  topicId,
  feedRequest,
}) => {
  const [draft, setDraft] = useState<RawDraftContentState<any> | null>(null);
  const { initAction, currentAction, finishAction } = useUserActions();
  const isOpen = currentAction === ActionType.WRITE_POST;
  const { myMember } = useUser();
  const { photo } = memberWrapper(myMember!).profile!;

  useEffect(() => {
    if (feedRequest && isOpen && !isPostCreating(feedRequest)) {
      closeNewPost();
    }
  }, [JSON.stringify(feedRequest)]);

  const closeNewPost = () => {
    finishAction();
  };
  const maybeWritePost = () => {
    return initAction(ActionType.WRITE_POST, 'top_rce_area');
  };

  const handleDraftSave = (draft: any) => {
    setDraft(draft);
    removeCreateNewPostDeepLink();
  };

  const handleCreatePost = (content: any, topicId?: string) => {
    onCreatePost(content, topicId);
    setDraft(null);
    removeCreateNewPostDeepLink();
  };

  return (
    <>
      <NewPost
        onClick={maybeWritePost}
        profilePhoto={photo?.url!}
        draft={draft!}
      />
      <NewPostModal
        initialContentState={draft!}
        isPostPublishing={isPostCreating(feedRequest)}
        isOpen={isOpen}
        onSubmit={handleCreatePost}
        onSaveDraft={handleDraftSave}
        topicId={topicId}
        discardPost={closeNewPost}
      />
    </>
  );
};

WritePost.displayName = 'WritePost';
