import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import React from 'react';
import { Private, Public } from '../../../../common/components/Icons';

export type PrivacyLabels = {
  title: string;
  label: string;
  icon: React.ReactNode;
};

export interface PrivacyUIMap {
  [key: string]: PrivacyLabels;
}

export const PrivacyMap: PrivacyUIMap = {
  [ApiTypes.v1.GroupPrivacyLevel.PUBLIC]: {
    title: 'groups-web.types.public.name',
    label: 'groups-web.types.public.description',
    icon: <Public />,
  },
  [ApiTypes.v1.GroupPrivacyLevel.PRIVATE]: {
    title: 'groups-web.types.private.name',
    label: 'groups-web.types.private.description',
    icon: <Private />,
  },
  [ApiTypes.v1.GroupPrivacyLevel.SECRET]: {
    title: 'groups-web.types.private.name',
    label: 'groups-web.visibility.hidden.description',
    icon: <Private />,
  },
};
