import React from 'react';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';
import { ShareIcon } from '../icons/ShareIcon';
import { st, classes } from './ShareButton.st.css';

export interface ShareButtonProps {
  onClick(): void;
  className?: string;
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  onClick,
  ...restProps
}) => {
  return (
    <IconButton
      aria-label="Share"
      data-hook="share-button"
      skin={Skins.Full}
      icon={<ShareIcon width="24px" height="24px" />}
      onClick={onClick}
      className={st(classes.root, {}, restProps.className)}
    />
  );
};
