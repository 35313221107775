import React from 'react';
import { ModalProps } from '../../../../../common/components/Modal/Modal';
import { DATA_HOOKS } from './dataHooks';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Dialog } from '../../../../../common/components/Dialog/Dialog';
import { Button } from '../../../../../common/components/Button';
import { compose } from '../../../../../common/utils/compose';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Theme, withTheme } from '../../../../../common/context/theme';

export interface WithdrawJoinRequestDialogProps extends ModalProps {
  onWithdrawJoinRequest(): void;
}

type Props = WithdrawJoinRequestDialogProps & WithTranslation & Theme;
const WithdrawJoinRequestDialogComponent: React.FC<Props> = (props) => {
  const { t, onWithdrawJoinRequest, forceBlackAndWhite, ...rest } = props;
  return (
    <Dialog
      title={t('groups-web.viewer.cancel-request-modal.title')}
      caption={t('groups-web.viewer.cancel-request-modal.body')}
      buttons={
        <>
          <Button
            forceBlackAndWhite={forceBlackAndWhite}
            onClick={onWithdrawJoinRequest}
            priority={PRIORITY.primary}
            data-hook={DATA_HOOKS.withdrawJoinRequestConfirmationButton}
          >
            {t('groups-web.viewer.cancel-request-modal.cta')}
          </Button>
        </>
      }
      {...rest}
    />
  );
};

const enhance = compose(withTranslation(), withTheme);

export const WithdrawJoinRequestDialog = enhance(
  WithdrawJoinRequestDialogComponent,
) as React.ComponentType<WithdrawJoinRequestDialogProps>;
