import React from 'react';
import { MemberInvitesConsumer } from './MemberInvitesContext';
import { MemberInvites } from './MemberInvites';

export const withMemberInvites = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof MemberInvites>> => (props) => {
  return (
    <MemberInvitesConsumer>
      {(memberInvites) => {
        return <WrappedComponent {...(props as P)} {...memberInvites} />;
      }}
    </MemberInvitesConsumer>
  );
};
