import React from 'react';
import { UploadProps } from '../../../../common/ContentEditor/UploadFile';
import { GroupContextConsumer } from '../Group/GroupContext';
import { GroupActionsContext } from './GroupActionsContext';

export interface WithUploadProps extends UploadProps {}
export const withUploadAction = <P extends WithUploadProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithUploadProps>> => (props) => (
  <GroupContextConsumer>
    {({ uploadedRegistry }) => (
      <GroupActionsContext.Consumer>
        {({ uploadFiles, setEditMode }) => (
          <WrappedComponent
            {...(props as P)}
            setEditMode={setEditMode}
            uploadFiles={uploadFiles}
            uploadedRegistry={uploadedRegistry}
          />
        )}
      </GroupActionsContext.Consumer>
    )}
  </GroupContextConsumer>
);
